import classNames from "classnames";
import { useEffect, useState } from "react";
import SideNavigation, {
  SideNavigationProps,
} from "../side-navigation/SideNavigation";
import Logo, { LogoType } from "../../common/logo/Logo";
import HamburgerIcon from "../../common/svg/Hamburger";
import { allColors } from "../../../util/colors";
import { useOverlayContext } from "../../../context/OverlayContext";

export type MobileNavigationProps = {
  sideNavigation?: SideNavigationProps;
  currentPath?: string;
  classes?: string;
};

export default function MobileNavigation(props: MobileNavigationProps) {
  const { sideNavigation, currentPath, classes }: MobileNavigationProps = props;

  const { isOverlayOpen, setIsOverlayOpen, setOverlayContent } =
    useOverlayContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenDelayed, setIsOpenDelayed] = useState<boolean>(false);

  const openMobileMenu = () => {
    setIsOpen(true);
    setIsOverlayOpen(true);
    setIsOpenDelayed(true);
    setOverlayContent(
      <SideNavigation
        {...sideNavigation}
        isMobileNavigation
        currentPath={currentPath}
        onCloseMobileNav={() => setIsOverlayOpen(false)}
      />
    );
  };
  const closeMobileMenu = () => {
    setIsOverlayOpen(false);
  };

  useEffect(() => {
    if (!isOverlayOpen) {
      setIsOpen(false);
      setTimeout(() => {
        setIsOpenDelayed(false);
      }, 250);
    }
  }, [isOverlayOpen]);

  return (
    <div
      className={classNames(
        "mobile-navigation",
        { "mobile-navigation--open": isOpenDelayed },
        classes
      )}
    >
      <header className="mobile-navigation__header">
        <button className="mobile-navigation__logo-button">
          <Logo type={LogoType.Full} classes="mobile-navigation__logo" />
          <span className="sr-only">Gatik omni home</span>
        </button>
        <button
          className="mobile-navigation__hamburger-button"
          onClick={() => (isOpen ? closeMobileMenu() : openMobileMenu())}
        >
          <span className="sr-only">Open navigation menu</span>
          <HamburgerIcon stroke={allColors.gray[700]} />
        </button>
      </header>
    </div>
  );
}
