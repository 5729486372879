import classNames from "classnames";
import { useSmBreakpoint } from "../../util/breakpoints";
import MobileNavigation from "../navigation/mobile-navigation/MobileNavigation";
import SideNavigation, {
  SideNavigationProps,
} from "../navigation/side-navigation/SideNavigation";
import SettingsIcon from "../common/svg/Settings";
import SquareMapIcon from "../common/svg/SquareMap";
import { useLocation, useSearchParams } from "react-router-dom";
import { useOverlayContext } from "../../context/OverlayContext";
import Overlay from "../common/overlay/Overlay";
import ThreeLayersIcon from "../common/svg/ThreeLayers";
import { useUserContext } from "../../context/UserContext";
import { checkIsStaff } from "../../util/util";

export type PageProps = {
  children: any;
  classes?: string;
};

export default function PageTemplate(props: PageProps) {
  const { children, classes }: PageProps = props;
  const isSm = useSmBreakpoint();
  const location = useLocation();
  const { userInfo } = useUserContext();
  const [filterParams, setFilterParams] = useSearchParams();

  const { isOverlayOpen, overlayContent, setIsOverlayOpen } =
    useOverlayContext();

  const sideNavContent: SideNavigationProps = {
    topMenu: {
      items: [
        {
          label: "Live Tracking",
          icon: <SquareMapIcon />,
          childItems: [
            {
              label: "Trips",
              linkUrl: "/live-tracking/trips?day=today",
            },
            {
              label: "Stops",
              linkUrl: "/live-tracking/stops?day=today",
            },
          ],
        },
        {
          label: "Ops Portal",
          icon: <ThreeLayersIcon />,
          linkUrl: "/ops/",
          isExternalLink: true,
          hideLink: !checkIsStaff(userInfo?.admin_user),
        },
      ],
    },
    bottomMenu: {
      items: [
        {
          label: "Settings",
          icon: <SettingsIcon />,
          linkUrl: "/settings",
        },
      ],
    },
  };

  return (
    <div className={classNames("page", classes)}>
      {isSm ? (
        <SideNavigation {...sideNavContent} currentPath={location.pathname} />
      ) : (
        <MobileNavigation
          sideNavigation={sideNavContent}
          currentPath={location.pathname}
        />
      )}
      <div
        className="page__container"
        // removes main page from tab index when mobile side nav or other drawer/modal is open
        {...{ inert: isOverlayOpen ? "" : undefined }}
      >
        {children}
      </div>
      <Overlay
        isActive={isOverlayOpen}
        onClick={() => {
          setIsOverlayOpen(false);
          // if overlay content is a trip timeline, remove the search param
          filterParams.delete("view_trip");
          setFilterParams(filterParams);
        }}
        srText="Close menu"
        content={overlayContent}
      />
    </div>
  );
}
