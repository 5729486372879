import classNames from "classnames";
import {
  TripStatus,
  StopEventStatus,
  Stop,
  StopSequenceStatus,
} from "../../../types/trip";
import TripProgressItem from "./TripProgressItem";
import TripProgressItemsCondensed from "./TripProgressItemsCondensed";
import AlertTriangleIcon from "../../common/svg/AlertTriangle";
import { allColors } from "../../../util/colors";
import StyledTooltip from "../../common/tooltip/StyledTooltip";

export interface TripProgressBarProps {
  tripStatus: TripStatus;
  stops: Stop[];
  classes?: string;
}

export default function TripProgressBar(props: TripProgressBarProps) {
  const { tripStatus, stops, classes } = props;

  // removes origin and final destination
  const filteredStops = stops.slice(1, -1);

  // Returns the stop that is currently in progress
  const firstStopNotDeparted = filteredStops.find(
    (stop) =>
      stop.event_status !== StopEventStatus.AVDeparted &&
      stop.sequence_status !== StopSequenceStatus.Skipped
  );
  // Returns the index of the stop that is currently in progress
  const firstStopNotDepartedIndex = filteredStops.findIndex(
    (stop) =>
      stop.event_status !== StopEventStatus.AVDeparted &&
      stop.sequence_status !== StopSequenceStatus.Skipped
  );

  // Returns all stops that are skipped
  const skippedStops = filteredStops.filter(
    (stop) => stop.sequence_status === StopSequenceStatus.Skipped
  );

  // This value accounts for if all stops are departed
  const currentProgress =
    firstStopNotDepartedIndex === -1
      ? filteredStops.length
      : firstStopNotDepartedIndex;

  // Should condensed stops be shown at start of progress bar
  const showStartCondensedStops =
    filteredStops.length > 5 && currentProgress >= 3;
  // Number shown for condensed stops
  const startCondensedStopsCount = Math.min(
    currentProgress - 1,
    filteredStops.length - 4
  );

  // Should condensed stops be shown at end of progress bar
  const showEndCondensedStops =
    filteredStops.length > 5 && filteredStops.length - currentProgress >= 4;
  // Number shown for condensed stops
  const endCondensedStopsCount = Math.min(
    filteredStops.length - currentProgress - 2,
    filteredStops.length - 4
  );

  const tripStatusText =
    tripStatus === TripStatus.Cancelled ? (
      <b>Cancelled</b>
    ) : tripStatus === TripStatus.Completed ? (
      <b>Completed</b>
    ) : tripStatus === TripStatus.Scheduled ? (
      <b>At origin</b>
    ) : tripStatus === TripStatus.TrackingTimeout ? (
      <b>Trip tracking timed out</b>
    ) : tripStatus === TripStatus.InProgress &&
      firstStopNotDeparted?.event_status === StopEventStatus.AVArrived ? (
      <b>Arrived at Stop {currentProgress + 1}</b>
    ) : tripStatus === TripStatus.InProgress && firstStopNotDeparted ? (
      <b>In transit to Stop {currentProgress + 1}</b>
    ) : tripStatus === TripStatus.InProgress && !firstStopNotDeparted ? (
      <b>In transit to final destination</b>
    ) : (
      <b>Status unknown</b>
    );

  return (
    <div className={classNames("trip-progress-bar", classes)}>
      <div className="trip-progress-bar__stops">
        {filteredStops.length > 0 &&
        (tripStatus === TripStatus.Scheduled ||
          tripStatus === TripStatus.Completed) ? (
          <TripProgressItem tripStatus={tripStatus} stop={filteredStops[0]} />
        ) : (
          <>
            {showStartCondensedStops && (
              <TripProgressItemsCondensed stops={startCondensedStopsCount} />
            )}
            {filteredStops.map((stop, index) => {
              if (
                // Show all stops when there's 5 or less stops
                filteredStops.length <= 5 ||
                // Show first 4 when there's no start condensed and is condensed end
                (!showStartCondensedStops &&
                  showEndCondensedStops &&
                  index < 4) ||
                // Show last 4 when there's no start condensed and is condensed end
                (showStartCondensedStops &&
                  !showEndCondensedStops &&
                  index > filteredStops.length - 5) ||
                // Show middle 3 stops starting with in progress in the middle when there's both start and end condensed stops
                (showStartCondensedStops &&
                  showEndCondensedStops &&
                  index < currentProgress + 2 &&
                  index > currentProgress - 2)
              ) {
                return (
                  <TripProgressItem
                    key={index}
                    stop={stop}
                    inProgress={currentProgress === index}
                    // animation duration is set this way so that the speed is consistent regardless of the number of stops
                    inProgressAnimationSpeed={
                      1000 / Math.min(filteredStops.length, 5)
                    }
                    tripStatus={tripStatus}
                  />
                );
              } else return null;
            })}
            {showEndCondensedStops && (
              <TripProgressItemsCondensed stops={endCondensedStopsCount} />
            )}
          </>
        )}
      </div>
      <div className="trip-progress-bar__text-content">
        <div className="trip-progress-bar__left-text-content">
          {tripStatusText}
        </div>
        <div className="trip-progress-bar__right-text-content flex flex-row items-center">
          <span>
            <b>{currentProgress - skippedStops.length}</b> of{" "}
            {filteredStops.length} stops
          </span>
          {skippedStops.length > 0 && (
            <StyledTooltip
              header={"Trip may contain skipped stops."}
              placement={"top"}
            >
              <AlertTriangleIcon
                fill={allColors.gray[800]}
                width="15"
                height="15"
                classes="ml-2"
              />
            </StyledTooltip>
          )}
        </div>
      </div>
      <progress
        className="sr-only"
        value={
          tripStatus === TripStatus.Completed
            ? filteredStops.length + 1
            : currentProgress
        }
        max={filteredStops.length + 1}
      ></progress>
    </div>
  );
}
