import { createContext, useContext, useEffect, useState } from "react";
import { useScrollbarWidth } from "../util/util";

interface OverlayContextValue {
  isOverlayOpen: boolean;
  overlayContent: JSX.Element | null;
  setIsOverlayOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOverlayContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
}
type OverlayContextProps = {
  children?: any;
};

const OverlayContext = createContext<OverlayContextValue | undefined>(
  undefined
);

export default function OverlayProvider(props: OverlayContextProps) {
  const { children }: OverlayContextProps = props;
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
  const [overlayContent, setOverlayContent] = useState<JSX.Element | null>(
    null
  );
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState<boolean>(false);

  const scrollbarWidth = useScrollbarWidth();

  useEffect(() => {
    if (isOverlayOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      if (hasInitiallyLoaded) {
        document.body.style.overflow = "unset";
        document.body.style.paddingRight = "unset";
        setTimeout(() => {
          setOverlayContent(null);
        }, 250);
      }
      setHasInitiallyLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOverlayOpen]);

  return (
    <OverlayContext.Provider
      value={{
        isOverlayOpen,
        overlayContent,
        setIsOverlayOpen,
        setOverlayContent,
      }}
    >
      {children}
    </OverlayContext.Provider>
  );
}

// Catch errors when useContent is used outside of provider
export const useOverlayContext = () => {
  const overlayContext = useContext(OverlayContext);
  if (overlayContext === undefined) {
    throw new Error("useOverlayContext must be inside a OverlayProvider");
  }
  return overlayContext;
};
