import classNames from "classnames";
import {
  Stop,
  StopEventStatus,
  TimelinessStatus,
  TripStatus,
} from "../../../../types/trip";
import {
  getTimezoneCorrectedDate,
  getTimezoneText,
  isDayAfter,
} from "../../../../util/date-util";
import { useSmBreakpoint } from "../../../../util/breakpoints";
import CalendarIcon from "../../../common/svg/Calendar";
import { allColors } from "../../../../util/colors";
import { useUserContext } from "../../../../context/UserContext";

export type TripTimelineTableProps = {
  stop: Stop;
  isOrigin?: boolean;
  useMyTimezone?: boolean;
  classes?: string;
};

export default function TripTimelineTable(props: TripTimelineTableProps) {
  const { stop, isOrigin, useMyTimezone, classes } = props;

  const { userInfo } = useUserContext();

  const isSm = useSmBreakpoint();

  const isStopTimeDayAfter = (date: string) => {
    return (
      stop.trip_scheduled_start &&
      isDayAfter(stop.trip_scheduled_start, date, stop.timezone)
    );
  };
  const timezoneText = () => {
    return useMyTimezone && userInfo?.timezone
      ? getTimezoneText(userInfo.timezone)
      : stop.timezone
        ? getTimezoneText(stop.timezone)
        : "UTC";
  };

  const getTime = (date: string) => {
    return `${getTimezoneCorrectedDate(date, useMyTimezone && userInfo?.timezone ? userInfo?.timezone : stop?.timezone).format("h:mm A")} ${timezoneText()}${isStopTimeDayAfter(date) ? " +1" : ""}`;
  };

  const displayTime = (date: string | undefined | null) =>
    date ? (
      <span className="trip-timeline-table__time">{getTime(date)}</span>
    ) : (
      <span>--</span>
    );

  const displayServiceTime = (minutes: number | undefined | null) =>
    minutes ? <span>{Math.round(minutes)} min</span> : <span>--</span>;

  const displayScheduledText = (hideText?: boolean) =>
    isSm ? (
      <span>{!hideText ? "Scheduled" : ""}</span>
    ) : (
      <CalendarIcon
        classes="mr-3"
        width="16"
        height="16"
        stroke={allColors.gray[500]}
      />
    );

  return (
    <table
      className={classNames(
        "trip-timeline-table text-gray-900 font-normal",
        classes
      )}
    >
      <tbody>
        {!isOrigin && (
          <tr>
            <td
              className={classNames({
                "bg-success-400":
                  stop.arrival_status === TimelinessStatus.Early ||
                  stop.arrival_status === TimelinessStatus.OnTime,
                "bg-warning-400": stop.arrival_status === TimelinessStatus.Late,
                "bg-gray-200":
                  !stop.arrival_status ||
                  stop.event_status === StopEventStatus.AwaitingAV,
                "bg-error-300":
                  stop.event_status === StopEventStatus.AwaitingAV &&
                  stop.trip_status === TripStatus.Cancelled,
              })}
            ></td>
            <td className="trip-timeline-table__actual">
              <div className="flex justify-between">
                <span>
                  {stop.event_status === StopEventStatus.AwaitingAV
                    ? "ETA"
                    : "Arrived at"}
                </span>
                {displayTime(stop.arrived_at)}
              </div>
            </td>
            <td className="trip-timeline-table__scheduled">
              <div className="trip-timeline-table__scheduled-content">
                {displayScheduledText()}
                {displayTime(stop.scheduled_arrival)}
              </div>
            </td>
          </tr>
        )}
        {!isOrigin && (
          <tr className="trip-timeline-table__service-time">
            <td className="bg-gray-200"></td>
            <td className="trip-timeline-table__actual">
              <div className="flex justify-between">
                <span>Time at stop</span>
                {displayServiceTime(stop.elapsed_service_time)}
              </div>
            </td>
            <td className="trip-timeline-table__scheduled">
              <div className="trip-timeline-table__scheduled-content">
                {displayScheduledText(true)}
                {displayServiceTime(stop.service_time)}
              </div>
            </td>
          </tr>
        )}
        <tr>
          <td
            className={classNames({
              "bg-success-400":
                stop.departure_status === TimelinessStatus.Early ||
                stop.departure_status === TimelinessStatus.OnTime,
              "bg-warning-400": stop.departure_status === TimelinessStatus.Late,
              "bg-gray-200":
                !stop.departure_status ||
                stop.event_status === StopEventStatus.AwaitingAV,
              "bg-error-300":
                stop.event_status !== StopEventStatus.AVDeparted &&
                stop.trip_status === TripStatus.Cancelled,
            })}
          ></td>
          <td className="trip-timeline-table__actual">
            <div className="flex justify-between">
              <span>Departed at</span>
              {displayTime(stop.departed_at)}
            </div>
          </td>
          <td className="trip-timeline-table__scheduled">
            <div className="trip-timeline-table__scheduled-content">
              {displayScheduledText()}
              {displayTime(stop.scheduled_departure)}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
