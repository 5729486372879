import classNames from "classnames";
import StyledButton from "../../../components/common/button/StyledButton";
import SignedOutTemplate from "../../../components/templates/SignedOutTemplate";
import SignedOutContainer from "../SignedOutContainer";
import { Formik } from "formik";
import StyledInput from "../../../components/form/input/StyledInput";
import ArrowIcon from "../../../components/common/svg/Arrow";
import { allColors } from "../../../util/colors";
import { SvgDirection, SvgType } from "../../../components/common/svg/types";
import { useEffect, useState } from "react";
import EyeHideIcon from "../../../components/common/svg/EyeHide";
import EyeShowIcon from "../../../components/common/svg/EyeShow";
import { useNavigate } from "react-router";
import { useSmBreakpoint } from "../../../util/breakpoints";
import { fetchUserInfo, login } from "../../../api/auth";
import * as Yup from "yup";
import { useLocalStorage } from "../../../util/local-storage";
import Snackbar, {
  SnackbarType,
} from "../../../components/common/snackbar/Snackbar";
import { useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../../components/common/loading-spinner/LoadingSpinner";
import { dayjs } from "../../../util/date-util";

export type SignInPageProps = {
  classes?: string;
};

export default function SignInPage(props: SignInPageProps) {
  const { classes }: SignInPageProps = props;
  const [, setLocalStorageValue] = useLocalStorage();

  const isSm = useSmBreakpoint();
  const navigate = useNavigate();
  const [filterParams, setFilterParams] = useSearchParams();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const [authExpiredMessage, setAuthExpiredMessage] = useState<string | null>(
    null
  );
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  useEffect(() => {
    const authParam = filterParams.get("auth");
    if (authParam === "expired") {
      setAuthExpiredMessage(
        "Your authentication has expired. Please log back in."
      );
    }
  }, [filterParams]);

  const SignInSchema = Yup.object().shape({
    // have to use this long regex because Yup's email validation is not great
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email address"
      )
      .required("Required"),
    password: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (formErrorMessage) {
      filterParams.delete("auth");
      setFilterParams(filterParams);
      setAuthExpiredMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrorMessage]);

  const getInitialUserInfo = async () => {
    const userInfo = localStorage.getItem("user");
    if (userInfo) {
      const parsedUserInfo = JSON.parse(userInfo);
      await fetchUserInfo()
        .then((resp) => {
          setLocalStorageValue({
            ...parsedUserInfo,
            admin_user: resp.admin_user,
            avatar: resp.avatar,
            customer_name: resp.customer_name,
            first_name: resp.first_name,
            last_name: resp.last_name,
            // If no user timezone is set, use browser timezone.
            timezone: resp.timezone || dayjs.tz.guess(),
          });
          // Redirect occurs in SignedOutTemplate.tsx
        })
        .catch(() => {
          setFormErrorMessage("Something went wrong while logging you in.");
          setIsLoggingIn(false);
        });
    }
  };

  return (
    <SignedOutTemplate>
      <SignedOutContainer headerButtonType="sign_up">
        <div className={classNames("sign-in-page", classes)}>
          <div className="text-center mb-12">
            <h1 className="text-display-xs sm:text-display-sm font-semibold mb-2">
              Welcome back
            </h1>
            <p className="text-md text-gray-600 font-medium">
              Please enter your details.
            </p>
          </div>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={SignInSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              await login(values)
                .then((response) => {
                  if (response.access) {
                    setLocalStorageValue({
                      email: values.email,
                      access_key: response.access,
                      refresh_key: response.refresh,
                    });
                    // TODO: Remove this once Ops Portal is integrated into a single app.
                    localStorage.setItem("access_token", response.access);
                    localStorage.setItem("refresh_token", response.refresh);

                    // Handles fetching user info and redirect
                    getInitialUserInfo();
                  } else {
                    console.warn("No access token.");
                  }
                  setIsLoggingIn(true);
                  setSubmitting(false);
                })
                .catch((error) => {
                  if (error.response.data.detail) {
                    setFormErrorMessage(error.response.data.detail);
                  }
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => {
              const isHandlingForm = isLoggingIn || isSubmitting;
              const isErrored =
                typeof errors.email === "string" ||
                typeof errors.password === "string";
              return (
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col items-stretch gap-10"
                >
                  <StyledInput
                    type="email"
                    name="email"
                    label="Email"
                    placeholder="Enter your email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={touched.email ? errors.email : undefined}
                  />
                  <StyledInput
                    type={showPassword ? "text" : "password"}
                    name="password"
                    label="Password"
                    placeholder="Enter your password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={touched.password ? errors.password : undefined}
                    endIcon={
                      <button
                        type="button"
                        className="ml-4"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <span className="sr-only">
                          {showPassword ? "Hide password" : "Show password"}
                        </span>
                        {!showPassword ? (
                          <EyeHideIcon
                            type={SvgType.Solid}
                            fill={allColors.grayLightMode[500]}
                          />
                        ) : (
                          <EyeShowIcon
                            type={SvgType.Solid}
                            fill={allColors.grayLightMode[500]}
                          />
                        )}
                      </button>
                    }
                  />
                  <StyledButton
                    size={isSm ? "md" : "2xl"}
                    color="primary"
                    isSubmit
                    disabled={isErrored}
                    outlined={isHandlingForm}
                    // Used to make the button un-clickable without disabled styles.
                    classes={classNames({
                      "pointer-events-none": isHandlingForm,
                    })}
                  >
                    {isHandlingForm ? "Logging in" : "Log in"}{" "}
                    {isHandlingForm ? (
                      <LoadingSpinner
                        size="2.5rem"
                        color={allColors.primary[600]}
                        classes="ml-4"
                      />
                    ) : (
                      <ArrowIcon
                        width="20"
                        height="20"
                        stroke={
                          isErrored ? allColors.gray[400] : allColors.white
                        }
                        direction={SvgDirection.Right}
                        classes="ml-4"
                      />
                    )}
                  </StyledButton>
                </form>
              );
            }}
          </Formik>
          <div className="text-center mt-2">
            <StyledButton
              size="md"
              color="primary"
              textOnly
              onClick={() => navigate("/reset-password")}
            >
              Forgot your password?
            </StyledButton>
          </div>
        </div>
        {formErrorMessage && (
          <Snackbar
            type={SnackbarType.Error}
            body={formErrorMessage}
            onDismiss={() => setFormErrorMessage(null)}
          />
        )}
        {authExpiredMessage && (
          <Snackbar
            type={SnackbarType.Info}
            body={authExpiredMessage}
            onDismiss={() => setAuthExpiredMessage(null)}
            autoDismiss
            autoDismissDelay="2500ms"
          />
        )}
      </SignedOutContainer>
    </SignedOutTemplate>
  );
}
